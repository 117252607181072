import { defineMessages } from 'react-intl';

export const messages = defineMessages({
  // General
  adjective_creative: {
    id: 'adjective-creative',
    defaultMessage: 'Creative.',
  },
  adjective_focused: {
    id: 'adjective-focused',
    defaultMessage: 'Focused.',
  },
  adjective_motivated: {
    id: 'adjective-motivated',
    defaultMessage: 'Motivated.',
  },
  adjective_persistent: {
    id: 'adjective-persistent',
    defaultMessage: 'Persistent.',
  },
  landing_page_link_to_resume: {
    id: 'landing-page-resume-link',
    description: 'link to resume',
    defaultMessage: 'Resume',
  },
  job_title: {
    id: 'job-title',
    defaultMessage: 'Software Engineer',
  },
  // My Vacation Home - Project
  mvh_paragraph_one: {
    id: 'mvh-description-p-1',
    defaultMessage:
      'I created My Vacation Home as an information website for my home in Winter Park, CO that I am renting out. You can visit the site {link} or by scanning the QR code below.',
  },
  mvh_paragraph_two: {
    id: 'mvh-description-p-2',
    defaultMessage:
      'Users can navigate use buttons that hook into their mobile devices app store to download the application for the free local transit to get to and from the Winter Park Ski Resort.',
  },
  mvh_paragraph_three: {
    id: 'mvh-description-p-3',
    defaultMessage:
      'Users have a list of restaurants to explore with the ability to click and access their native OSs navigation application.',
  },
  mvh_best_on_mobile: {
    id: 'mvh-best-on-mobile',
    defaultMessage: 'Best on mobile',
  },
  mvh_here_link: {
    id: 'mvh-here-link',
    defaultMessage: 'HERE',
  },
  // Resume
  resume_experience_1: {
    id: 'resume-date-present',
    defaultMessage: 'Present',
  },
  resume_experience_paragraph_1_sentence_1: {
    id: 'resume-experience-paragraph-1-sentence-1',
    defaultMessage: 'Design, implement, and ship a Data Profiling solution for users to easily explore and gather insights on their data. ',
  },
  resume_experience_paragraph_1_sentence_2: {
    id: 'resume-experience-paragraph-1-sentence-2',
    defaultMessage: 'C++, C#, TypeScript, React. ',
  },
  resume_experience_paragraph_1_sentence_3: {
    id: 'resume-experience-paragraph-1-sentence-3',
    defaultMessage:
      'Communicate effectively across organizational teams and focuses to build a world class product while providing product presentations to C-suite and shareholders. ',
  },
  resume_experience_paragraph_2_sentence_1: {
    id: 'resume-experience-paragraph-2-sentence-1',
    defaultMessage: 'Showcased knowledge and ability to perform at a high level and learn new technologies at a rapid pace. ',
  },
  resume_experience_paragraph_2_sentence_2: {
    id: 'resume-experience-paragraph-2-sentence-2',
    defaultMessage:
      'Contribute effectively to a full stack Analytics Dashboard solution for various Windows OS with a C++ engine, C# interface, JavaScript / React frontend, Python / Flask server. ',
  },
  resume_experience_paragraph_3_sentence_1: {
    id: 'resume-experience-paragraph-3-sentence-1',
    defaultMessage:
      'Applied creatively designed components to projects using AutoCAD Managed implementing payroll application and on-boarding 30 + employees Designed clean and precise file structure for documenting project data ',
  },
  resume_interests_list: {
    id: 'resume-interests-list',
    defaultMessage:
      'Traveling Robotics Design Quality IoT Baseball DataScience RaspberryPi Hiking Lego Snowboarding ComputerVision PingPong Competing Family Learning Art Investing Analytics ProblemSolving ForeignLanguages Physics Security Coffee',
  },
  resume_profile_paragraph_sentence_1: {
    id: 'resume-profile-paragraph-sentence-1',
    defaultMessage: 'I think anything is possible when you get enough people to believe in what they’re creating.',
  },
  resume_profile_paragraph_sentence_2: {
    id: 'resume-profile-paragraph-sentence-2',
    defaultMessage: 'I enjoy being challenged and building quality software alongside a motivated team on a mission to change the world.',
  },
  resume_profile_paragraph_sentence_3: {
    id: 'resume-profile-paragraph-sentence-3',
    defaultMessage: 'Sharing my knowledge and expertise while taking pride in learning from my peers.',
  },
  resume_profile_paragraph_sentence_4: {
    id: 'resume-profile-paragraph-sentence-4',
    defaultMessage: 'Understanding the low level details and grasping the high level goal to better instruct architecture and design.',
  },
  resume_project_1_description: {
    id: 'resume-project-1-description',
    defaultMessage:
      'Wakes you up with a game plan for how to start your day. Understand where you are on your personal goals and overall health.',
  },
  resume_project_2_description: {
    id: 'resume-project-2-description',
    defaultMessage:
      'Sleuth is dedicated to creating an even playing field for all ISPs. Sleuth can check your download speed and compare top download speeds amongst other ISPs',
  },
  resume_project_3_description: {
    id: 'resume-project-3-description',
    defualtMessage: 'IoT device to improve office/employee compatibility for a more productive work environment.',
  },
  resume_section_title_education: {
    id: 'resume-section-education',
    defaultMessage: 'Experience',
  },
  resume_section_title_experience: {
    id: 'resume-section-experience',
    defaultMessage: 'Experience',
  },
  resume_section_title_interests: {
    id: 'resume-section-interests',
    defaultMessage: 'Interests',
  },
  resume_section_title_profile: {
    id: 'resume-section-profile',
    defaultMessage: 'Profile',
  },
  resume_section_title_projects: {
    id: 'resume-section-projects',
    defaultMessage: 'Projects',
  },
  resume_section_title_skills: {
    id: 'resume-section-skills',
    defaultMessage: 'skills',
  },
});

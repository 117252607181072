import React from 'react';
import { Button, Container, Grid, IconButton, Paper, Typography, useTheme } from '@material-ui/core';
import { GitHub } from '@material-ui/icons';

import useStyles from './styles';
import { ICustomTheme } from '../../theme';
import chromeIcon from '../../images/google-chrome.svg';

function Sleuth() {
  const theme: ICustomTheme = useTheme();
  const classes = useStyles();
  return (
    <Container maxWidth='md'>
      <Paper elevation={3}>
        <Grid className={classes.mvhTextContainer} container direction='column' justify='space-between'>
          <Typography variant='h4'>Sleuth</Typography>
          <p>
            Sleuth can check your download speed and compare top download speeds amongst other ISPs. You can add Sleuth to your Chrome
            browser and run a check whenever your want.
          </p>
          <Grid container item justify='flex-end'>
            <Grid item>
              <Button
                href='https://chrome.google.com/webstore/detail/sleuth/ljcgfcpjeioeodhbpjjfamlikpgmnhno?hl=en-US'
                startIcon={<img src={chromeIcon} height={35} width={35} />}
                target='_blank'
              >
                Chrome Web Store
              </Button>

              <IconButton href='https://github.com/g64-sleuth' target='_blank'>
                <GitHub fontSize='large' />
              </IconButton>
            </Grid>
          </Grid>
        </Grid>
      </Paper>
    </Container>
  );
}

export default Sleuth;
